import React, { useState } from 'react';
// Import the pipeline function from transformers.js
import { pipeline, env } from '@xenova/transformers';

env.localModelPath = process.env.PUBLIC_URL + '/models/'
env.allowRemoteModels = false;
env.allowLocalModels = true;

const TextToSpeech = () => {
    const [text, setText] = useState('');
    const [loading, setLoading] = useState(false);

    // Function to handle text-to-speech conversion
    const handleTTS = async () => {
        try {
            setLoading(true);
            // Initialize the TTS pipeline (replace 'text-to-speech' with the correct task identifier and model name)
            const synthesizer = await pipeline('image-to-text', 'Xenova/speecht5_tts', { quantized: false });
            const speaker_embeddings = 'https://huggingface.co/datasets/Xenova/transformers.js-docs/resolve/main/speaker_embeddings.bin';
            const out = await synthesizer('Hello, my dog is cute', { speaker_embeddings });
            console.log("speaker_embeddings", out);
            // Create an audio URL and play it
            // const audioUrl = URL.createObjectURL(audioBlob);
            // const audio = new Audio(audioUrl);
            // audio.play();

            setLoading(false);
        } catch (error) {
            console.error('Error performing text-to-speech:', error);
            setLoading(false);
        }
    };

    return (
        <div>
            <textarea
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Type something..."
            />
            <button onClick={handleTTS} disabled={loading}>
                {loading ? 'Converting...' : 'Convert to Speech'}
            </button>
        </div>
    );
};

export default TextToSpeech;
