import axios from '../../utils/axios';
import React, { useCallback, useEffect, useRef, useState } from 'react';

// minimum size for each part in multipart upload (5mb)
const MIN_PART_SIZE = 5 * 1024 * 1024;

// maximum number of concurrent uploads
const MAX_CONCURRENT_UPLOADS = 3;

const useVideoRecorder = ({ width, height, ivid }) => {
    // states
    const [isRecording, setIsRecording] = useState(false);
    const [isMeetingActive, setIsMeetingActive] = useState(false);
    const [uploadId, setUploadId] = useState(null);
    const [filename, setFilename] = useState('');
    const [videoStream, setVideoStream] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);

    // references
    const mediaRecorderRef = useRef();
    const partsRef = useRef([]);
    const chunkIndexRef = useRef(0);
    const chunkBufferRef = useRef([]);
    const uploadQueueRef = useRef([]);
    const totalPartsRef = useRef(0);
    const uploadedPartsRef = useRef(0);
    const activeUploadsRef = useRef(new Set());
    const uploadIdRef = useRef(null);
    const hasInitiatedUploadRef = useRef(false);

    useEffect(() => {
        if (isRecording) {
            startRecording();
        } else {
            stopRecording();
        }
    }, [isRecording]);

    const startMeeting = useCallback(async () => {
        if (hasInitiatedUploadRef.current) return;
        hasInitiatedUploadRef.current = true;
        setIsMeetingActive(true);
        if (!uploadIdRef.current) {
            const filename = `video-${Date.now().toString()}_v.mp4`;
            setFilename(filename);
            const newUploadId = await initiateMultipartUpload(filename);
            uploadIdRef.current = newUploadId;
            setUploadId(newUploadId);
        }
    }, []);

    const processUploadQueue = useCallback(async () => {
        while (
            uploadQueueRef.current.length > 0 &&
            activeUploadsRef.current.size < MAX_CONCURRENT_UPLOADS
        ) {
            const { blob, partNumber } = uploadQueueRef.current.shift();
            activeUploadsRef.current.add(partNumber);

            await uploadChunksToS3(blob, filename, uploadIdRef.current, partNumber)
                .then((part) => {
                    partsRef.current.push(part);
                    console.log(`Part ${partNumber} uploaded successfully.`);
                })
                .catch((error) => {
                    console.error(`Error uploading part ${partNumber}:`, error);
                })
                .finally(() => {
                    activeUploadsRef.current.delete(partNumber);
                    processUploadQueue();
                });
        }
    }, [filename]);

    const addToUploadQueue = useCallback(
        (blob) => {
            const partNumber = chunkIndexRef.current + 1;
            chunkIndexRef.current = partNumber;
            uploadQueueRef.current.push({ blob, partNumber });
            processUploadQueue();
        },
        [processUploadQueue]
    );

    const processChunkBuffer = useCallback(
        async (isLastChunk = false) => {
            const buffer = chunkBufferRef.current;
            if (buffer.length === 0) return;

            const totalSize = buffer.reduce((sum, chunk) => sum + chunk.size, 0);

            if (totalSize >= MIN_PART_SIZE || isLastChunk) {
                const blob = new Blob(buffer, { type: 'video/mp4' });
                addToUploadQueue(blob);
                chunkBufferRef.current = [];
            }
        },
        [addToUploadQueue]
    );

    const handleDataAvailable = useCallback(
        async (event) => {
            if (event.data.size > 0) {
                chunkBufferRef.current.push(event.data);
                await processChunkBuffer();
            }
        },
        [processChunkBuffer]
    );

    const endMeeting = useCallback(async () => {
        setIsMeetingActive(false);
        setIsUploading(true);
        setUploadProgress(0);

        if (mediaRecorderRef.current && mediaRecorderRef.current.state !== 'inactive') {
            mediaRecorderRef.current.stop();
        }

        await processChunkBuffer(true);

        // counting total parts
        totalPartsRef.current = uploadQueueRef.current.length + activeUploadsRef.current.size;
        uploadedPartsRef.current = 0;

        while (uploadQueueRef.current.length > 0 || activeUploadsRef.current.size > 0) {
            await new Promise((resolve) => setTimeout(resolve, 100));
        }

        await completeMultipartUpload(filename, uploadIdRef.current, partsRef.current);

        setUploadId(null);
        uploadIdRef.current = null;
        partsRef.current = [];
        chunkIndexRef.current = 0;
        chunkBufferRef.current = [];
        uploadQueueRef.current = [];
        totalPartsRef.current = 0;
        uploadedPartsRef.current = 0;

        if (isRecording) {
            stopRecording();
        }

        setIsUploading(false);
    }, [filename, uploadId, isRecording, processChunkBuffer]);

    const startRecording = () => {
        navigator.mediaDevices
            .getUserMedia({
                video: { width, height },
                audio: true,
            })
            .then((stream) => {
                setVideoStream(stream);
                mediaRecorderRef.current = new MediaRecorder(stream);
                mediaRecorderRef.current.ondataavailable = handleDataAvailable;
                mediaRecorderRef.current.start(1000);
            })
            .catch((error) => {
                console.error('Error accessing media devices', error);
                setIsRecording(false);
            });
    };

    const stopRecording = () => {
        if (mediaRecorderRef.current) {
            try {
                if (mediaRecorderRef.current.state === 'recording') {
                    mediaRecorderRef.current.stop();
                }
                if (mediaRecorderRef.current.stream) {
                    mediaRecorderRef.current.stream.getTracks().forEach((track) => track.stop());
                }
            } catch (error) {
                console.error('Error stopping recording:', error);
            } finally {
                mediaRecorderRef.current = null;
            }
        }
    };

    const initiateMultipartUpload = async (filename) => {
        const token = localStorage.getItem('jwt_token');
        const response = await axios.post(
            '/candidate/v1/interviews/initiate-upload',
            { fileName: filename },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.data.data;
        return data;
    };

    const updateProgress = useCallback(() => {
        if (totalPartsRef.current === 0) return;
        const progress = (uploadedPartsRef.current / totalPartsRef.current) * 100;
        setUploadProgress(Math.min(progress, 99)); // caping at 99% until final request
    }, []);

    const getUploadUrl = async (filename, uploadId, partNumber) => {
        const token = localStorage.getItem('jwt_token');

        const response = await axios.post(
            '/candidate/v1/interviews/get-presigned-url',
            { fileName: filename, partNumber, uploadId },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        const data = await response.data.data;
        return data;
    };

    const uploadChunksToS3 = async (chunk, filename, uploadId, partNumber) => {
        try {
            const token = localStorage.getItem('jwt_token');
            const url = await getUploadUrl(filename, uploadId, partNumber);

            const response = await fetch(url, {
                method: 'PUT',
                body: chunk,
                headers: {
                    'Content-Type': 'application/octet-stream',
                },
            });

            if (!response.ok) {
                throw new Error('Upload failed');
            }

            const eTag = response.headers.get('ETag').replace(/"/g, '');

            // updating progress
            uploadedPartsRef.current++;
            updateProgress();

            return {
                ETag: eTag,
                PartNumber: partNumber,
            };
        } catch (error) {
            console.error('Error uploading chunk:', error);
            throw error;
        }
    };

    const completeMultipartUpload = async (filename, uploadId, parts) => {
        const token = localStorage.getItem('jwt_token');
        const response = await axios.post(
            '/candidate/v1/interviews/complete-upload',
            { fileName: filename, uploadId, parts, ivid },
            {
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (!response.status === 200) {
            throw new Error('Failed to complete multipart upload');
        }

        const data = await response.data;
        console.log(`Upload completed successfully: ${data.data}`);
    };

    return {
        isRecording,
        setIsRecording,
        startMeeting,
        endMeeting,
        isMeetingActive,
        mediaRecorderRef,
        videoStream,
        uploadProgress,
        isUploading,
    };
};

export default useVideoRecorder;
