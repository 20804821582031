import React, { useState, useEffect, useRef } from 'react';
import { Typography, Button, Card, CardContent, Select, MenuItem, FormControl, InputLabel, Snackbar, Alert, Container, Grid, Box } from '@mui/material';
import './../assets/app.css'
import useScreenshotUploader from '../components/hooks/useScreenshotUploader';

function PreInterviewTests() {
    const [videoDevices, setVideoDevices] = useState([]);
    const [audioDevices, setAudioDevices] = useState([]);
    const [selectedCamera, setSelectedCamera] = useState('');
    const [selectedMicrophone, setSelectedMicrophone] = useState('');
    const [videoStream, setVideoStream] = useState(null);
    const [audioStream, setAudioStream] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const audioContextRef = useRef(null);
    const analyserRef = useRef(null);
    const animationFrameRef = useRef(null);
    const canvasRef = useRef(null);
    const [permissionStatus, setPermissionStatus] = useState({ video: 'prompt', audio: 'prompt' });
    const [isCameraTestSuccessful, setIsCameraTestSuccessful] = useState(false);
    const [isMicrophoneTestSuccessful, setIsMicrophoneTestSuccessful] = useState(false);
    const { captureRef, startScreenshotCapture } = useScreenshotUploader(10000, 1280, 720, "AVCheck");

    useEffect(() => {
        const cleanup = startScreenshotCapture();
        return cleanup;
    }, [startScreenshotCapture]);

    useEffect(() => {
        Promise.all([
            navigator.permissions.query({ name: 'camera' }),
            navigator.permissions.query({ name: 'microphone' })
        ]).then(([cameraPermission, microphonePermission]) => {
            setPermissionStatus({
                video: cameraPermission.state,
                audio: microphonePermission.state
            });
        });
    }, []);

    const requestMediaAccess = async (mediaType) => {
        try {
            if (mediaType === 'video') {
                await navigator.mediaDevices.getUserMedia({ video: true });
                setPermissionStatus(prev => ({ ...prev, video: 'granted' }));
            } else if (mediaType === 'audio') {
                await navigator.mediaDevices.getUserMedia({ audio: true });
                setPermissionStatus(prev => ({ ...prev, audio: 'granted' }));
            }
        } catch (error) {
            console.error(`Error accessing ${mediaType}:`, error);
            if (mediaType === 'video') {
                setPermissionStatus(prev => ({ ...prev, video: 'denied' }));
            } else if (mediaType === 'audio') {
                setPermissionStatus(prev => ({ ...prev, audio: 'denied' }));
            }
        }
    };

    useEffect(() => {
        // Fetch available video and audio devices
        navigator.mediaDevices.enumerateDevices().then(devices => {
            const videoInputs = devices.filter(device => device.kind === 'videoinput');
            const audioInputs = devices.filter(device => device.kind === 'audioinput');

            setVideoDevices(videoInputs);
            setAudioDevices(audioInputs);

            if (videoInputs.length > 0) {
                setSelectedCamera(videoInputs[0].deviceId);
            }

            if (audioInputs.length > 0) {
                setSelectedMicrophone(audioInputs[0].deviceId);
            }
        });
    }, []);

    const handleCameraTest = () => {
        if (permissionStatus.video === 'granted') {
            if (selectedCamera) {
                navigator.mediaDevices.getUserMedia({ video: { deviceId: selectedCamera } })
                    .then(stream => {
                        setVideoStream(stream);
                        setSnackbarMessage('Camera is working!');
                        setIsCameraTestSuccessful(true);
                        setOpenSnackbar(true);
                    })
                    .catch(error => {
                        console.error('Error accessing camera:', error);
                        setSnackbarMessage('Error accessing camera.');
                        setIsCameraTestSuccessful(false);
                        setOpenSnackbar(true);
                    });
            }
        } else {
            requestMediaAccess('video');
        }
    };

    const startAudioMeter = (stream) => {
        if (audioContextRef.current && analyserRef.current) {
            audioContextRef.current.close(); // Close any existing audio context
        }

        const audioContext = new AudioContext();
        audioContextRef.current = audioContext;
        const source = audioContext.createMediaStreamSource(stream);
        const analyser = audioContext.createAnalyser();
        analyserRef.current = analyser;
        source.connect(analyser);
        analyser.fftSize = 256;
        const bufferLength = analyser.frequencyBinCount;
        const dataArray = new Uint8Array(bufferLength);

        const draw = () => {
            animationFrameRef.current = requestAnimationFrame(draw);
            analyser.getByteFrequencyData(dataArray);

            const canvas = canvasRef.current;
            const ctx = canvas.getContext('2d');
            const width = canvas.width;
            const height = canvas.height;
            ctx.clearRect(0, 0, width, height);

            let barWidth = (width / bufferLength) * 2.5;
            let barHeight;
            let x = 0;

            for (let i = 0; i < bufferLength; i++) {
                barHeight = dataArray[i] / 2;
                ctx.fillStyle = 'rgb(50,150,50)';
                ctx.fillRect(x, height - barHeight, barWidth, barHeight);
                x += barWidth + 1;
            }
        };

        draw();
    };

    const stopAudioMeter = () => {
        if (animationFrameRef.current) {
            cancelAnimationFrame(animationFrameRef.current);
            animationFrameRef.current = null;
        }
        if (audioContextRef.current) {
            audioContextRef.current.close();
            audioContextRef.current = null;
        }
        const canvas = canvasRef.current;
        const ctx = canvas.getContext('2d');
        ctx.clearRect(0, 0, canvas.width, canvas.height);
    };

    // Modify handleMicrophoneTest to start the audio meter
    const handleMicrophoneTest = () => {
        if (permissionStatus.audio === 'granted') {
            if (selectedMicrophone) {
                navigator.mediaDevices.getUserMedia({ audio: { deviceId: selectedMicrophone } })
                    .then(stream => {
                        setAudioStream(stream);
                        startAudioMeter(stream);
                        setSnackbarMessage('Microphone is working!');
                        setIsMicrophoneTestSuccessful(true);
                        setOpenSnackbar(true);
                    })
                    .catch(error => {
                        console.error('Error accessing microphone:', error);
                        setIsMicrophoneTestSuccessful(false);
                        setSnackbarMessage('Error accessing microphone.');
                        setOpenSnackbar(true);
                    });
            }
        } else {
            requestMediaAccess('audio');
        }
    };

    // Modify handleStopMicrophoneTest to stop the audio meter
    const handleStopMicrophoneTest = () => {
        if (audioStream) {
            audioStream.getTracks().forEach(track => track.stop());
            setAudioStream(null);
            stopAudioMeter();
            setSnackbarMessage('Microphone test stopped.');
            setOpenSnackbar(true);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
    };

    const handleCameraChange = (event) => {
        setSelectedCamera(event.target.value);
    };

    const handleMicrophoneChange = (event) => {
        setSelectedMicrophone(event.target.value);
    };

    // Function to stop the camera test
    const handleStopCameraTest = () => {
        if (videoStream) {
            videoStream.getTracks().forEach(track => track.stop());
            setVideoStream(null);
            setSnackbarMessage('Camera test stopped.');
            setOpenSnackbar(true);
        }
    };

    const handleCloseTab = () => {
        window.close();
    };

    return (
        <div ref={captureRef} style={{ padding: "1px", backgroundColor: "lightgrey" }}>
            <Container maxWidth="false" className="containerStyle-device-test">
                <Box style={{ padding: '1rem', backgroundColor: '#E1F5FE', marginBottom: '2rem' }}>
                    <Typography variant="h5" padding='2rem' gutterBottom>
                        Test Your Device - NovelHire Interviews
                    </Typography>

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel>Camera</InputLabel>
                                <Select value={selectedCamera} label="Camera" onChange={handleCameraChange}>
                                    {videoDevices.map((device, index) => (
                                        <MenuItem key={index} value={device.deviceId}>{device.label || `Camera ${index + 1}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Card variant="outlined" sx={{ backgroundColor: '#E1F5FE', mt: 2 }}>
                                <CardContent>
                                    <Button variant="contained" onClick={handleCameraTest} disabled={!!videoStream}>Test Camera</Button>
                                    <Button variant="contained" color="error" onClick={handleStopCameraTest} sx={{ ml: 2 }} disabled={!videoStream}>Stop Test</Button>
                                    {videoStream && <video autoPlay style={{ width: '100%', marginTop: '10px' }} ref={video => { if (video) video.srcObject = videoStream }} />}
                                </CardContent>
                            </Card>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <FormControl fullWidth>
                                <InputLabel>Microphone</InputLabel>
                                <Select value={selectedMicrophone} label="Microphone" onChange={handleMicrophoneChange}>
                                    {audioDevices.map((device, index) => (
                                        <MenuItem key={index} value={device.deviceId}>{device.label || `Microphone ${index + 1}`}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <Card variant="outlined" sx={{ backgroundColor: '#E1F5FE', mt: 2 }}>
                                <CardContent>
                                    <Button variant="contained" onClick={handleMicrophoneTest} disabled={!!audioStream}>Test Microphone</Button>
                                    <Button variant="contained" color="error" onClick={handleStopMicrophoneTest} sx={{ ml: 2 }} disabled={!audioStream}>Stop Test</Button>
                                    <canvas ref={canvasRef} style={{ width: '100%', height: '60px', marginTop: '10px' }}></canvas>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>

                    <Button
                        variant="contained"
                        color="secondary"
                        sx={{ mt: 2 }}
                        onClick={handleCloseTab}
                        disabled={!isCameraTestSuccessful || !isMicrophoneTestSuccessful}>
                        Close Window
                    </Button>

                    <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                        <Alert onClose={handleCloseSnackbar} severity="success">
                            {snackbarMessage}
                        </Alert>
                    </Snackbar>
                </Box>
            </Container>
        </div>
    );
}

export default PreInterviewTests;