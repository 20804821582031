/**
 * axios setup to use mock service
 */

import axios from 'axios';

const axiosServices = axios.create({
  baseURL: process.env.REACT_APP_API_URL || 'http://backend.novelhire.com:5000/'
});

export function setAxiosToken(token) {
  if (token) {
    axiosServices.defaults.headers.common['Authorization'] = `Bearer ${token}`;
  } else {
    delete axiosServices.defaults.headers.common['Authorization'];
  }
}

// Set the token initially (if it exists)
const initialToken = localStorage.getItem('jwt_token');
setAxiosToken(initialToken);

// interceptor for http responses
axiosServices.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject((error.response && error.response.data) || 'Wrong Services')
);

export default axiosServices;
