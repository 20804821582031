import { useState, useEffect } from 'react';

const useFullScreen = () => {
    const [isFullScreen, setIsFullScreen] = useState(false);

    const toggleFullScreen = () => {
        if (!document.fullscreenElement) {
            document.documentElement.requestFullscreen().catch((e) => {
                console.error(`Error attempting to enable full-screen mode: ${e.message} (${e.name})`);
            });
        } else {
            if (document.exitFullscreen) {
                document.exitFullscreen().catch((e) => {
                    console.error(`Error attempting to disable full-screen mode: ${e.message} (${e.name})`);
                });
            }
        }
    };

    useEffect(() => {
        const handleFullScreenChange = () => setIsFullScreen(!!document.fullscreenElement);

        document.addEventListener("fullscreenchange", handleFullScreenChange);

        return () => document.removeEventListener("fullscreenchange", handleFullScreenChange);
    }, []);

    return { isFullScreen, toggleFullScreen };
};

export default useFullScreen;
