// third-party
import { combineReducers } from 'redux';
import createWebStorage from 'redux-persist/lib/storage/createWebStorage';

// project imports
import interviewsSliceReducer from './slices/interviewsSlice';
import settingsSliceReducer from './slices/settingsSlice';

const createNoopStorage = () => ({
  getItem() {
    return Promise.resolve(null);
  },
  setItem(_key, value) {
    return Promise.resolve(value);
  },
  removeItem() {
    return Promise.resolve();
  }
});

const storage = typeof window !== 'undefined' ? createWebStorage('local') : createNoopStorage();

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-'
};

// ==============================|| COMBINE REDUCER ||============================== //

const reducer = combineReducers({
  interviews: interviewsSliceReducer,
  settings: settingsSliceReducer
});

export { rootPersistConfig, reducer };
