import { useEffect, useRef } from "react";
import html2canvas from "html2canvas";
import axios from "axios";
import { uploadScreenshotToServer } from "../../store/slices/interviewsSlice";
import { dispatch, useSelector } from "../../store";

const useScreenshotUploader = (interval = 30000, maxWidth = 1280, maxHeight = 720, pagename = "default") => {
    const captureRef = useRef(null);
    const { interviewsData } = useSelector((state) => state.interviews);

    // console.log("interviewsData", interviewsData[0].ivid)
    // Function to capture and upload screenshots at regular intervals
    const captureAndUpload = async () => {
        if (captureRef.current) {
            try {
                let ivid = interviewsData ? interviewsData[0].ivid : "default";
                // Capture screenshot using html2canvas
                const canvas = await html2canvas(captureRef.current);

                // Resize the canvas to the desired resolution (HD 1280x720 max)
                const resizedCanvas = resizeCanvas(canvas, maxWidth, maxHeight);

                // Convert resized canvas to Data URL
                const dataUrl = resizedCanvas.toDataURL("image/png");
                const file = dataUrlToFile(dataUrl, `screenshot_${Date.now()}.png`);
                // console.log("file", file);
                if (file) {
                    dispatch(uploadScreenshotToServer(ivid, file, pagename));
                }
            } catch (error) {
                console.error("Error capturing and uploading screenshot:", error);
            }
        }
    };

    // Function to resize the canvas
    const resizeCanvas = (canvas, maxWidth, maxHeight) => {
        const ratio = Math.min(maxWidth / canvas.width, maxHeight / canvas.height);
        const width = canvas.width * ratio;
        const height = canvas.height * ratio;

        // Create a new canvas with the resized dimensions
        const resizedCanvas = document.createElement("canvas");
        resizedCanvas.width = width;
        resizedCanvas.height = height;

        const ctx = resizedCanvas.getContext("2d");
        ctx.drawImage(canvas, 0, 0, width, height);

        return resizedCanvas;
    };

    const dataUrlToFile = (dataUrl, filename) => {
        const arr = dataUrl.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    // Start interval-based screenshot capturing
    const startScreenshotCapture = () => {
        const intervalId = setInterval(captureAndUpload, interval);

        return () => clearInterval(intervalId);
    };


    const manualCapture = async () => {
        await captureAndUpload();
    };

    // Return the ref for the component to attach to the element
    return {
        captureRef,
        startScreenshotCapture,
        manualCapture
    };
};

export default useScreenshotUploader;
