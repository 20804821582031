import React, { useState, useEffect } from 'react';
import { Box, Typography, CircularProgress, Alert, Button, Divider } from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import SuccessIcon from '@mui/icons-material/ThumbUpSharp';
import { useSelector } from 'react-redux';
import { addInterviewEvents, resetAllInterviewState, setisVideoMeeting, uploadFileToServer } from '../store/slices/interviewsSlice';
import { dispatch } from '../store';
import './../assets/app.css'
import { openVideoDB } from '../utils/AVUtils';
import useScreenshotUploader from '../components/hooks/useScreenshotUploader';

function ThankYou() {
    const ss_storeName = "captures";
    const vid_storeName = "videos";
    const aud_storeName = "audioChunks";
    const [videoUploading, setVideoUploading] = useState(false);
    const [audioUploading, setAudioUploading] = useState(false);
    const [localerror, setLocalError] = useState(false);
    const [uploadSuccessful, setUploadSuccessful] = useState(true);
    const { interviewsData, isVideoMeeting, error, postInterviewResponse, isVideoUploaded, isAudioUploaded } = useSelector((state) => state.interviews);
    const AUDIO_DBNAME = "AudioDatabase";
    const AUDIO_DBVERSION = 3;
    const { captureRef, startScreenshotCapture, manualCapture } = useScreenshotUploader(10000, 1280, 720, "TY");

    useEffect(() => {
        const cleanup = startScreenshotCapture();
        return cleanup;
    }, [startScreenshotCapture]);

    useEffect(() => {
        if (!isAudioUploaded || !isVideoUploaded) {
            handleReUpload();
        }
    }, []);
    // console.log("Interview Data", interviewsData[0]?.ivid);
    const handleCloseWindow = () => {
        window.close();
    };

    const uploadVideo = () => {
        let video_id = interviewsData ? interviewsData[0]?.ivid : null;
        if (!video_id)
            return
        openVideoDB().then((db) => {
            const transaction = db.transaction([vid_storeName], "readwrite");
            const store = transaction.objectStore(vid_storeName);
            const getVideo = store.get(video_id);

            getVideo.onsuccess = async function (event) {
                const videoBlob = event.target.result;
                if (videoBlob) {
                    // Upload the videoBlob to the server
                    setVideoUploading(true);
                    await dispatch(uploadFileToServer(videoBlob, postInterviewResponse?.uid, 'v'));
                }
            };
            getVideo.onerror = function (event) {
                console.error("Error retrieving video:", event.target.errorCode);
            };
        }).catch(console.error);
    };

    const combineAndUploadAudio = async () => {
        const request = indexedDB.open(AUDIO_DBNAME, AUDIO_DBVERSION);

        // request.onupgradeneeded = function (event) {
        //     const db = event.target.result;
        //     if (!db.objectStoreNames.contains('audioChunks')) {
        //         db.createObjectStore("audioChunks", { keyPath: "id" });
        //     }
        // };

        request.onerror = function () {
            console.error("Error opening the database");
            alert("Failed to combine audio chunks. Please try again.");
        };

        request.onsuccess = async function (event) {
            const db = event.target.result;
            const transaction = db.transaction(["audioChunks"], "readonly");
            const store = transaction.objectStore("audioChunks");
            const getAllRequest = store.getAll();

            getAllRequest.onerror = function () {
                console.error("Error fetching audio chunks:", getAllRequest.error);
                alert("Failed to retrieve audio chunks. Please try again.");
            };

            getAllRequest.onsuccess = async function () {
                const allData = getAllRequest.result;
                const allChunks = allData.flatMap(data => data.chunks);
                const combinedBlob = new Blob(allChunks, { type: 'audio/webm' });

                if (allData.length > 0 && allChunks.length > 0) {
                    setAudioUploading(true);
                    await dispatch(uploadFileToServer(combinedBlob, postInterviewResponse.uid, 'a'));
                }
            };
        };
    };

    function disposeAllMeta() {
        dispatch(resetAllInterviewState());
    }

    function deleteVideoFromIndexedDB(key) {
        const request = indexedDB.open("VideoDatabase", 1);
        request.onsuccess = function (event) {
            const db = event.target.result;
            const deleteTransaction = db.transaction(["videos"], "readwrite");
            const store = deleteTransaction.objectStore("videos");
            const deleteRequest = store.delete(key);

            deleteRequest.onsuccess = function () {
                console.log("Video deleted from the database.");
            };

            deleteRequest.onerror = function (event) {
                console.error("Error deleting video:", event.target.errorCode);
            };
        };
    }

    function deleteAudioFromIndexedDB() {
        const request = indexedDB.open(AUDIO_DBNAME, AUDIO_DBVERSION);

        request.onerror = function () {
            console.error("Error opening the database");
            alert("Failed to combine audio chunks. Please try again.");
        };

        request.onsuccess = async function (event) {
            const db = event.target.result;
            const deleteTransaction = db.transaction(["audioChunks"], "readwrite");
            const deleteStore = deleteTransaction.objectStore("audioChunks");
            deleteStore.clear();
        };
    }

    const handleReUpload = async () => {
        const audio_Response = await combineAndUploadAudio();
        if (isVideoMeeting) {
            const video_Response = uploadVideo();
        }
    }

    useEffect(() => {
        if (isAudioUploaded) {
            deleteAudioFromIndexedDB();
            setAudioUploading(false);
        }
        if (isVideoUploaded) {
            if (interviewsData && interviewsData[0]) {
                let video_id = interviewsData[0]?.ivid;
                deleteVideoFromIndexedDB(video_id);
                setVideoUploading(false);
            }
        }
        if (isAudioUploaded && isVideoUploaded) {
            if (interviewsData && interviewsData.length > 0) {
                dispatch(addInterviewEvents(interviewsData[0]?.ivid, 7));
                manualCapture();
                setUploadSuccessful(true);
                disposeAllMeta();
                dispatch(setisVideoMeeting(false));
            }
        }
        else {
            setLocalError(true)
        }
    }, [isAudioUploaded, isVideoUploaded])

    return (
        <div ref={captureRef} style={{ padding: "1px", backgroundColor: "lightgrey" }}>
            <Box
                display="flex"
                flexDirection="column"
                alignItems="center"
                justifyContent="center"
                height="100vh"
                className="containerStyle-thankyou">
                <Box sx={{ backgroundColor: '#E1F5FE', minHeight: '200px', minWidth: '50%' }} padding="20px" display="flex" flexDirection="column" alignItems="center"
                    justifyContent="center">
                    <Typography variant="h4" gutterBottom style={{ color: 'ActiveBorder', fontWeight: 'bold' }}>
                        Thank You!
                    </Typography>
                    {/* <Typography variant="h6" gutterBottom style={{ color: 'white' }}>
                Thank you for attending the meeting. You can close the window now.
            </Typography> */}

                    {(audioUploading || videoUploading) ? (
                        <Box display="flex" flexDirection="column" alignItems="center">
                            <Alert
                                severity="warning"
                                icon={<WarningIcon fontSize="inherit" />}
                                variant="filled"
                                style={{ marginBottom: '16px', fontSize: '16px', color: 'ActiveBorder' }}
                            >
                                Please wait!! Interview data is being recorded...., please do not close the window.
                            </Alert>
                            <CircularProgress color="secondary" />
                        </Box>
                    ) : (uploadSuccessful && (
                        <>
                            <Box display="flex" flexDirection="column" alignItems="center" sx={{ justifyContent: 'center' }}>
                                <div>
                                    <SuccessIcon fontSize="large" color='success' />
                                </div>
                                <div style={{ textAlign: 'center' }}>
                                    <Typography variant="body1" style={{ color: '#4CAF50', fontSize: '20px', fontWeight: 'bold', marginBottom: '8px' }}>
                                        Interview data has been recorded successfully!
                                    </Typography>
                                    <Typography variant="body1" style={{ fontSize: '16px' }}>
                                        You can now safely close the window.
                                    </Typography>
                                </div>
                            </Box>
                        </>
                    )
                    )}
                    {(error) && (
                        <>
                            <Box flexDirection="row" sx={{ justifyContent: 'center' }}>
                                <Typography variant="h6" style={{ color: 'red', fontWeight: 'bold', marginBottom: '16px' }}>
                                    Error ! Upload of interview details failed due to unknown reason. Kindly retry after 10 seconds.
                                </Typography>
                                <Button variant="contained" color="primary" onClick={handleReUpload}>
                                    Retry
                                </Button>
                                {/* <Button variant="contained" color="error" onClick={handleCloseWindow}>
                        Contact Support
                    </Button> */}
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </div>
    );
}

export default ThankYou;
