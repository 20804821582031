import React from 'react';
import { Container, Typography, Button, Box } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from "react-router-dom";
import { useSelector } from '../store';

function CommonErrors() {
    const { interviewTokens } = useSelector((state) => state.interviews);
    const navigate = useNavigate();
    const restartProcess = () => {
        if (interviewTokens && interviewTokens.int_details?.int_share_id)
            navigate('/initmeeting?meeting_id=' + interviewTokens.int_details?.int_share_id);
    }

    return (
        <Container maxWidth="lg" style={{ textAlign: 'center', paddingTop: '5rem' }}>
            <ErrorOutlineIcon style={{ fontSize: 100, color: 'teal' }} />
            <Typography variant="h2" gutterBottom style={{ color: 'teal' }}>
                Something went wrong
            </Typography>
            <Typography variant="subtitle1" gutterBottom>
                We're having trouble processing your request. Please restart and try again. If the problem persists please contact support
            </Typography>
            <Box mt={4}>
                <Button variant="contained" color="secondary" style={{ marginRight: '1rem' }} onClick={restartProcess}>
                    Restart Interview
                </Button>
                <Button variant="outlined" color="secondary" href="https://help.novelhire.com/portal/en/signin" target="_blank">
                    Contact Support
                </Button>
            </Box>
        </Container>
    )
}

export default CommonErrors;