import { Button, IconButton, Typography } from '@mui/material';
import Tour from 'reactour';
import CloseIcon from '@mui/icons-material/Close';

const buttonStyle = {
    backgroundColor: 'red',
    color: 'white',
    border: 'none',
    padding: '10px 20px',
    cursor: 'pointer',
    borderRadius: '5px',
};

const CustomCloseButton = ({ onClick }) => {
    return (
        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <IconButton color="error" onClick={onClick}>
                <CloseIcon />
            </IconButton>
        </div>
    );
};

export default function TourComponent({ closeTour, tourStates }) {
    const steps = [
        {
            selector: '.interface',
            content: () => (
                <div>
                    <CustomCloseButton onClick={closeTour} />
                    <p>
                        This is your chat interface where you can conduct interviews or interact
                        with others in real-time. Let's take a quick tour to get you familiar with
                        the key features!
                    </p>
                </div>
            ),
        },
        {
            selector: '.jobTitle',
            content: () => (
                <div>
                    <CustomCloseButton onClick={closeTour} />
                    <p>This is your job title for which your interview is conducted</p>
                </div>
            ),
        },
        {
            selector: '.notifications',
            content: () => (
                <div>
                    <CustomCloseButton onClick={closeTour} />
                    <p>
                        If there are any issues, such as camera obstruction or needing to enable
                        fullscreen mode, warnings will appear at the top of the screen. Follow the
                        instructions to resolve these issues.
                    </p>
                </div>
            ),
        },
        {
            selector: '.typing-effect',
            content: () => (
                <div>
                    <CustomCloseButton onClick={closeTour} />
                    <p>Here, you will be able to view and listen to your interview questions.</p>
                </div>
            ),
        },
        {
            selector: '.videoPreview',
            content: () => (
                <div>
                    <CustomCloseButton onClick={closeTour} />
                    <p>In this section, you will see a preview of your video camera feed.</p>
                </div>
            ),
        },
        {
            selector: '.aiInterviewer',
            content: () => (
                <div>
                    <CustomCloseButton onClick={closeTour} />
                    <p>
                        This is your AI interviewer, Nova! Nova will be your interviewer for this
                        interview.
                    </p>
                </div>
            ),
        },
        {
            selector: '.listeningStatus',
            content: () => (
                <div>
                    <CustomCloseButton onClick={closeTour} />
                    <p>
                        This section indicates whether the interviewer is currently listening.
                        Please wait until the entire question appears on the screen before the
                        interviewer can hear your response.
                    </p>
                </div>
            ),
        },
        {
            selector: '.mic',
            content: () => (
                <div>
                    <CustomCloseButton onClick={closeTour} />
                    <p>You can toggle your microphone on and off using this button.</p>
                </div>
            ),
        },
        {
            selector: '.camera',
            content: () => (
                <div>
                    <CustomCloseButton onClick={closeTour} />
                    <p>You can toggle your camera on and off using this button.</p>
                </div>
            ),
        },
        {
            selector: '.screenShare',
            content: () => (
                <div>
                    <CustomCloseButton onClick={closeTour} />
                    <p>You can share your screen with the interviewer using this button.</p>
                </div>
            ),
        },
        {
            selector: '.nextButton',
            content: () => (
                <div>
                    <CustomCloseButton onClick={closeTour} />
                    <p>You can move to the next question using this button.</p>
                </div>
            ),
        },
        {
            selector: '.endButton',
            content: () => (
                <div>
                    <CustomCloseButton onClick={closeTour} />
                    <p>You can end the interview using this button.</p>
                </div>
            ),
        },
        {
            selector: '.time',
            content: () => (
                <div>
                    <CustomCloseButton onClick={closeTour} />
                    <p>This section indicates the time taken for current question</p>
                </div>
            ),
        },
        {
            selector: '.progressStepper',
            content: () => (
                <div>
                    <CustomCloseButton onClick={closeTour} />
                    <p>This section indicates the progress of the interview.</p>
                </div>
            ),
        },
    ];

    return (
        <Tour
            onRequestClose={closeTour}
            disableInteraction={true}
            steps={steps}
            isOpen={tourStates.isTourOpen}
            // maskClassName="mask-space"
            className="helper"
            rounded={5}
            accentColor={tourStates.accentColor}
            showNavigation={false}
            closeWithMask={false}
            showCloseButton={false}
            prevButton={<Button variant='contained'  size='small'>Prev</Button>}
            nextButton={<Button variant='contained'  size='small'>Next</Button>}
        />
    );
}
