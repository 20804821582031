import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    selectedCamera: null,
    selectedMicrophone: null,
};

const settings = createSlice({
    name: 'settings',
    initialState,
    reducers: {
        // set interviewsList
        setSelectedCam(state, action) {
            state.selectedCamera = action.payload;
        },

        setSelectedMic(state, action) {
            state.selectedMicrophone = action.payload;
        },

        resetSettings(state) {
            state.selectedCamera = null;
            state.selectedMicrophone = null;
        }
    }
});

export const {
    setSelectedCam,
    setSelectedMic
} = settings.actions;

export default settings.reducer;