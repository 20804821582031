import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import InfoIcon from '@mui/icons-material/Info';

function MeetingNotifications({ isVideoOff, obstructionDetected, isFullScreen, toggleFullScreen, handleVideoClick, windowFocused, tabActive }) {

    const handleClose = () => {
        // Handle close action
    };

    const handleEnable = (item) => {
        switch (item) {
            case 1:
                if (isVideoOff) {
                    handleVideoClick();
                }
                break;
            case 2:
                toggleFullScreen();
                break;
        }
    };

    return (
        <>
            <Box className="notifications" position="relative" display="flex" justifyContent="flex-start" alignItems="flex-start" style={{ padding: '5px', }}>
                {(obstructionDetected || isVideoOff) &&
                    <Box style={{
                        position: 'relative',
                        top: 0,
                        left: 0,
                        marginRight: '10px',
                        background: 'rgba(255, 0, 0, 0.7)',
                        color: 'white',
                        padding: '5px',
                        borderRadius: '5px',
                        maxWidth: '80%',
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <Typography variant="body1" style={{ flex: 1 }}>
                            Warning: Camera obstruction detected
                        </Typography>
                        <Button variant="text" size='small' onClick={() => handleEnable(1)} style={{ marginLeft: '10px', background: 'white', color: 'black' }}>
                            Enable
                        </Button>
                        <Tooltip arrow title="Please fix your video to ensure smooth interview process, loss of video will decrease your eligibility for this position!.">
                            <IconButton style={{ color: 'white' }}>
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>

                    </Box>
                }
                {(!isFullScreen) &&
                    <Box style={{
                        position: 'relative',
                        top: 0,
                        left: 0,
                        marginRight: '10px',
                        background: 'rgba(255, 0, 0, 0.7)',
                        color: 'white',
                        padding: '5px',
                        borderRadius: '5px',
                        maxWidth: '80%',
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <Typography variant="body1" style={{ flex: 1 }}>
                            Warning: Please enable full screen mode.
                        </Typography>
                        <Button variant="text" size='small' onClick={() => handleEnable(2)} style={{ marginLeft: '10px', background: 'white', color: 'black' }}>
                            Enable
                        </Button>
                        <Tooltip arrow title="Full Screen Disbled has been notified. Disabling full screen mode will make your interview void!">
                            <IconButton onClick={handleClose} style={{ color: 'white' }}>
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                }
                {(!tabActive || !windowFocused) &&
                    <Box style={{
                        position: 'relative',
                        top: 0,
                        left: 0,
                        marginRight: '10px',
                        background: 'rgba(255, 0, 0, 0.7)',
                        color: 'white',
                        padding: '5px',
                        borderRadius: '5px',
                        maxWidth: '80%',
                        display: 'flex',
                        alignItems: 'center',
                    }}>
                        <Typography variant="body1" style={{ flex: 1 }}>
                            Warning: Window change detected. Please come back to Interview window!.
                        </Typography>
                        <Button variant="text" size='small' onClick={() => handleEnable(3)} style={{ marginLeft: '10px', background: 'white', color: 'black' }}>
                            Click Here
                        </Button>
                        <Tooltip arrow title="We track your tab changes to prevent any mal practices happening during the interview!.">
                            <IconButton onClick={handleClose} style={{ color: 'white' }}>
                                <InfoIcon />
                            </IconButton>
                        </Tooltip>
                    </Box>
                }
            </Box>
        </>
    )
}

export default MeetingNotifications;