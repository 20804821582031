import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { useEffect, useState } from "react";


export default function RefreshDialog() {
    const [openRefreshDialog, setOpenRefreshDialog] = useState(false);

    useEffect(() => {
        const beforeUnloadHandler = (e) => {
            e.preventDefault();
            e.returnValue = '';
            setOpenRefreshDialog(true);
        };
        window.addEventListener('beforeunload', beforeUnloadHandler);
        return () => {
            window.removeEventListener('beforeunload', beforeUnloadHandler);
        };
    }, []);

    const handleRefreshDialogClose = () => {
        setOpenRefreshDialog(false);
    };

    return (
        <>
            <Dialog
                open={openRefreshDialog}
                onClose={handleRefreshDialogClose}
            >
                <DialogTitle>{"Warning"}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Are you sure you want to refresh the page? You will lose your current status.
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleRefreshDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button
                        onClick={() => {
                            handleRefreshDialogClose();
                            window.location.reload(); // Force reload the page
                        }}
                        color="primary"
                    >
                        Continue
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}