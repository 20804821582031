import React from 'react';
import { Slider, Typography, Box, styled } from '@mui/material';

const CustomSlider = styled(Slider)(({ theme }) => ({
    height: 20,
    paddingBottom: '10px',
    '& .MuiSlider-track': {
        border: 'none',
        height: 20,
        borderRadius: 4,
        backgroundColor: '#1976d2', // A shade of blue for the completed part
    },
    '& .MuiSlider-rail': {
        height: 20,
        borderRadius: 4,
        backgroundColor: 'rgba(255, 255, 255, 0.3)', // A lighter color with some transparency for the incomplete part
    },
    '& .MuiSlider-mark': {
        backgroundColor: 'rgba(255, 255, 255, 0.7)', // Slightly more visible marks
        height: 20,
        width: 1,
        '&.MuiSlider-markActive': {
            opacity: 1,
            backgroundColor: 'currentColor',
        },
    },
}));

function MeetingProgressStepper({ totalSteps = 10, currentStep }) {
    return (
        <Box
            className="progressStepper"
            sx={{
                width: '100%',
                padding: ' 0 10px 0 10px',
            }}
        >
            <CustomSlider
                value={currentStep}
                step={1}
                disabled
                min={0}
                components={{
                    Thumb: () => null, // This disables the thumb
                }}
                max={totalSteps}
                valueLabelDisplay="off"
            />
        </Box>
    );
}

export default MeetingProgressStepper;
